/*
 * This component expects a minimum of  two elements are passed in the slot. The order
 * of these elements is not important.
 *
 * the first element is the popover element. This element needs the attribute data-popover.
 * One element will be the anchor element, which the popover use as reference to position itself.
 *
 * These elements need to be present the moment that the showPopover method is called.
 *
 * This has the following attributes that you can set:
 * - popover-position: "bottom" | "top" (default: bottom)
 *   This attribute determines the position of the popover relative to the anchor element.
 * - center-align: boolean (default: false)
 *   This attribute determines if the popover should be centered relative to the anchor element.
 * */
class VillaPopoverHandler extends HTMLElement {
  anchorElement: any;
  popoverElement: any;
  hasPopover: boolean = Object.prototype.hasOwnProperty.call(
    HTMLElement.prototype,
    "popover",
  );
  popoverPosition: "bottom" | "top" = "bottom";
  centerAlign: boolean = false;

  constructor() {
    super();

    this.attachShadow({ mode: "open" });

    if (!this.shadowRoot) {
      console.error("Shadow DOM is not found");
      return;
    }

    // TODO: test if the screen reader if role status speaks the content of the popover
    this.shadowRoot.innerHTML = `
        <div anchor>
          <slot></slot>
          <div popover="auto" class="popover" role="status">
            <slot name="popover"></slot>
          </div>
        </div>
        <style>
          @supports (transition-behavior: allow-discrete) {
            .popover {
              opacity: 0;
            }
          }

          .popover {
            /*reset */
            padding: 0;
            border: 0;
            margin: 0;
            background: transparent;

            transition:
              opacity var(--motion-fast) var(--motion-easein),
              overlay var(--motion-fast) var(--motion-easein) allow-discrete,
              display var(--motion-fast) var(--motion-easein) allow-discrete;

            position: absolute;
            left: var(--left);
          }

          :host([popover-position="bottom"]) .popover {
            top: calc(var(--bottom) + 1rem);
          }

          :host([popover-position="top"]) .popover {
            top: calc(var(--top) - 1rem);
          }

          @supports not selector([popover]:popover-open) {
            .popover {
              display: none;
            }

            [anchor] {
              position: relative;
            }

            .popover-fallback {
              display: block;
              z-index: 1;
            }

            :host([popover-position="bottom"]) .popover-fallback {
              margin-top: 1rem;
            }

            :host([popover-position="top"]) .popover-fallback {
              /* Naive way of positioning the popover, could be optimized */
              top: -5rem;
            }
          }

          /*
            * A fade out animation can't be supported yet because feature
           * detection for starting styles is not reliable across browsers
           */
          :host([fade-in]) .popover:popover-open {
            opacity: 1;
            transition:
              opacity var(--motion-slow) var(--motion-easeout),
              overlay var(--motion-slow) var(--motion-easeout) allow-discrete,
              display var(--motion-slow) var(--motion-easeout) allow-discrete;
          }

          @starting-style {
            :host([fade-in]) .popover:popover-open {
              opacity: 0;
            }
          }
        </style>
      `;
  }

  connectedCallback() {
    this.popoverElement = this.shadowRoot?.querySelector(".popover");

    const popoverPositionAttribute = this.getAttribute("popover-position");

    if (popoverPositionAttribute) {
      this.popoverPosition = this.getAttribute("popover-position") as
        | "bottom"
        | "top";
    } else {
      this.setAttribute("popover-position", "bottom");
    }

    const centerAlignAttribute = this.getAttribute("center-align");

    if (centerAlignAttribute) {
      this.centerAlign = true;
    }
  }

  showPopover() {
    if (this.hasPopover) {
      this.anchorElement = this.shadowRoot?.querySelector("[anchor]");

      this.popoverElement.showPopover();

      const rect = this.anchorElement?.getBoundingClientRect();

      if (!rect) {
        console.error(
          "Anchor element is not found on screen for getBoundingClientRect",
        );
        return;
      }

      if (this.popoverPosition === "bottom") {
        this.popoverElement.style.setProperty(
          "--bottom",
          `${rect.bottom + document.documentElement.scrollTop}px`,
        );
      }

      if (this.popoverPosition === "top") {
        const popoverRect = this.popoverElement?.getBoundingClientRect();

        if (!popoverRect) {
          console.error(
            "Popover element is not found on screen for getBoundingClientRect",
          );
          return;
        }

        this.popoverElement.style.setProperty(
          "--top",
          `${rect.top - popoverRect.height + document.documentElement.scrollTop}px`,
        );
      }

      if (this.centerAlign) {
        const leftCenterAlign =
          rect.left + rect.width / 2 - this.popoverElement.offsetWidth / 2;

        this.popoverElement.style.setProperty(
          "--left",
          `${leftCenterAlign < 0 ? 0 : leftCenterAlign}px`,
        );
      } else {
        this.popoverElement.style.setProperty("--left", `${rect?.left}px`);
      }
    } else {
      this.popoverElement.classList.add("popover-fallback");
    }
  }

  hidePopover() {
    if (this.hasPopover) {
      this.popoverElement.hidePopover();
    } else {
      this.popoverElement.classList.remove("popover-fallback");
    }
  }
}

customElements.define("villa-popover-handler", VillaPopoverHandler);
